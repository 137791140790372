
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIFixedTimePicker from "@/views/resources/documentation/element-ui/form/time-select/FixedTimePicker.vue";
import EUIFixedTimeRange from "@/views/resources/documentation/element-ui/form/time-select/FixedTimeRange.vue";

export default defineComponent({
  name: "time-select",
  components: {
    EUIFixedTimePicker,
    EUIFixedTimeRange
  },
  setup() {
    setCurrentPageTitle("TimeSelect");
  }
});
